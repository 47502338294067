import { useState } from 'react'
import { FirmwareStatus } from '../components/FirmwareField'
import { DeviceDetailsEndpoints, SoftwaresEndpoints } from 'api/axios/axiosAPIGroups'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { usePairingContext } from 'hooks/usePairingContext'
import { PAIRING_STEPS, pairingSteps } from 'core/pairing/pairing'
import { useLoading } from 'hooks/useLoading'
import { DeployActionEnum, DeviceDeploymentDTO, SoftwareNameEnum } from 'api/api_code'

type ActionInProgressState = {
  [uuid: string]: boolean
}
export enum FirmwareAction {
  BULK_UPGRADE = 'BULK_UPGRADE',
}
const useCameraFirmwareActions = () => {
  const [firmwareActionInProgress, setFirmwareActionInProgress] = useState<ActionInProgressState>(
    {}
  )
  const { requestHandler } = useHTTPRequestHandler()
  const { setPairingModalState, setCurrentStep, pairedDeviceUUID } = usePairingContext()
  const { setLoading } = useLoading()

  const handleBulkUpgrade = (devices: DeviceDeploymentDTO[], status: boolean) => {
    const devicesInProgress: ActionInProgressState = devices.reduce((acc, item) => {
      item.device_uuid && (acc[item.device_uuid] = status)
      return acc
    }, {})

    setFirmwareActionInProgress((prevFirmwareAction) => {
      return { ...prevFirmwareAction, ...devicesInProgress }
    })
  }

  const resetFirmwareActions = () => {
    setFirmwareActionInProgress({})
  }

  const initCameraFirmwareAction = {
    [FirmwareStatus.UPGRADE]: ({ cameraUUID }) => {
      requestHandler({
        requestPromise: DeviceDetailsEndpoints.deployAutomatictvDevicesDeviceUuidDeployPost(
          cameraUUID,
          true
        ),
        onOkCallback: () => {
          setLoading(false)
          setFirmwareActionInProgress((prevFirmwareAction) => {
            return { ...prevFirmwareAction, [cameraUUID]: true }
          })
        },
        onKo: {
          onKoCallback: () => {
            setLoading(false)
            setFirmwareActionInProgress((prevFirmwareAction) => {
              return { ...prevFirmwareAction, [cameraUUID]: false }
            })
          },
          overrideCallback: true,
        },
        autoLoading: false,
      })
    },
    [FirmwareStatus.INSTALL]: ({ cameraUUID }) => {
      pairedDeviceUUID.current = cameraUUID
      setCurrentStep(pairingSteps[PAIRING_STEPS.DEPLOY])
      setPairingModalState(true)
    },
    [FirmwareAction.BULK_UPGRADE]: (cameraUUIDs) => {
      requestHandler({
        requestPromise: SoftwaresEndpoints.deploySoftwareSoftwaresSoftwareDeployPost(
          SoftwareNameEnum.Automatictv,
          DeployActionEnum.Upgrade,
          { device_uuids: cameraUUIDs }
        ),
        onOkCallback: (devicesStatus: DeviceDeploymentDTO[]) => {
          setLoading(false)
          handleBulkUpgrade(devicesStatus, true)
        },
        onKo: {
          onKoCallback: (devicesStatus) => {
            setLoading(false)
            handleBulkUpgrade(devicesStatus, false)
          },
          overrideCallback: true,
        },
        autoLoading: false,
      })
    },
  }
  return {
    cameraFirmwareAction: initCameraFirmwareAction,
    firmwareActionInProgress,
    resetFirmwareActions,
  }
}
export default useCameraFirmwareActions
